.cmp-contact-infos { overflow: hidden;
  .cmp-sub-cont-contact-info {
    .cmp-sub-title { @extend .has-margin-bottom-sm; }
    .cmp-sub-wysiwyg {
      h2 { @extend .title; @extend .is-5;
        &:not(:last-child) {
          @extend .has-margin-bottom-sm;
        }
      }
      .cmp-sub-contact-person {
        a[href^="mailto:"] { display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
      }
    }
    .cmp-sub-contact-person {
      .cmp-sub-role { @extend .has-margin-bottom-xs; }
      .cmp-sub-email {
        .cmp-sub-val { display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
      }
    }
  }
}
