$counter: (one, two, three, four);
$suffix-plural: ('', s);
$widths: (
  narrow: (
    divisor: none,
    width: 1%
  ),
  half: (
    divisor: none,
    width: 50%
  ),
  third: (
    divisor: 3,
    width: 33.3333%
  ),
  quarter: (
    divisor: 4,
    width: 25%
  ),
  fifth: (
    divisor: 5,
    width: 20%
  )
);

@each $name, $config in $widths {
  $width: map-get($config, width);
  @if map-get($config, divisor) == none {
    .table{
      th.is-#{ $name },
      td.is-#{ $name } { width: #{$width}; }
    }
  } @else {
    @for $i from 1 through map-get($config, divisor) - 1 {
      .table {
        th.is-#{ nth($counter, $i) }-#{ $name }#{ nth($suffix-plural, min($i, 2)) },
        td.is-#{ nth($counter, $i) }-#{ $name }#{ nth($suffix-plural, min($i, 2)) } { width: #{$width}; }
      }
    }
  }
}
