/* MASTER-LOADER COMPONENT */
.cmp-master-loader { display: none; position: relative; width: 100%;
  .sub-bar { height: 2px;
      background: linear-gradient(
          to right,
          transparentize($color-master-loader, 0.88),
          transparentize($color-master-loader, 0.88) 0%,
          transparentize($color-master-loader, 0.12) 88%,
          $color-master-loader 100%
      );
      transition: width 0.2s ease-out; }
  &.loading { display: block;
    .sub-bar {  }
  }
}
