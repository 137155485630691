/**
* CONSTANTS
**/

/* Paths */
$path-assets: '../static/assets';

$logo-zumtobel:  url("#{$path-assets}/brand/zumtobel/logo.svg");
$logo-tridonic:  url("#{$path-assets}/brand/tridonic/logo.png");
$logo-thorn:  url("#{$path-assets}/brand/thorn/logo.svg");
$logo-thorneco:  url("#{$path-assets}/brand/thorneco/logo.svg");

$spinner: url('#{$path-assets}/spinner.gif');

$img-check-mark: url('#{$path-assets}/check_mark.svg');
$img-warning: url('#{$path-assets}/warning.svg');

/* Colors */
$color-app-background: #ededed;
$color-master-loader: $primary !default;

/* Typography */
$family-generic: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

/* Sizes */
$is-size-6-5: 0.875rem;

/* Margin/Padding calculator */

$sizeUnit: rem;

$z-index-autosuggest: 100;
$z-index-header: 500;
$z-index-gallery: 600;


/**
* IMPORTS
*/

@import './mixins/_all.scss';
@import './generated/_all.scss';


/**
* OVERRIDE, OR BULMA-CLASS-EXTENSIONS
*/

.card { border-radius: $radius-large; overflow: hidden; }


/**
* DEFAULT-CLASSES
**/

.is-nowrap { white-space: nowrap; }
.invalid-by-msg,
.is-checkradio.invalid-by-msg + label:before { border-color: $danger !important; }
.visibility-hidden { visibility: hidden; }
.has-block-margin { margin-bottom: 1.5rem; }

td,
th {
  &.is-numeric { text-align: right; }
}

.cont-wysiwyg {
  a { text-decoration: underline;
    &:not([href^='mailto:']){ }
  }
  em { font-style: normal; font-weight: bold; }
  b,
  strong { font-weight: bold; }
}

.sub-expandcollapse { margin-top: 5px;
  .cmp-sub-link { position: relative; color: #000; border: none; padding: 0;
    &:after { @include mx-arrow(#000, 'bottom', 'middle'); position: relative; margin-left: 0.5em; margin-top: -0.25em;
      top: -0.2em;
    }
  }
  &.cmp-sub-expanded {
    .cmp-sub-link {
      &:after { @include mx-arrow(#000, 'top', 'middle'); position: relative; margin-top: -0.25em; top: -0.1em; }
    }
  }
}
.is-mobile-cta {
  @include mobile {
    position: fixed; bottom: 0; width: 100%; left: 0; z-index: 99; border-radius: 0; font-size: 1.25rem;
  }
}
