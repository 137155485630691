.view-article {
  .main-content {
    .sub-cont {
      .sub-cont-title {
          .level-item { justify-content: flex-start; }
        .sub-cont-brand-logo {
          .sub-logo { max-height: 3rem; max-width: 11.5rem; }
        }
        @include from($tablet) {
          .sub-cont-article-number { text-align: right; }
        }
      }
      .sub-detail { margin-top: -8px;
        .cont-additional-infos {
          .button { justify-content: flex-start; }
        }
        @include until($tablet) {
          .cont-additional-infos { margin: 0; }
        }
        @include from($tablet) {
          .cont-wysiwyg { order: 1; }
          .cont-additional-infos { order: 2;
            .button { width: 100%; }
          }
        }
      }
    }
  }
}
