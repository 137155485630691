@charset "utf-8";

/*
* BULMA-DEFAULTS-OVERRIDES
*/
$footer-padding: 0 !default;


/*
* IMPORTS
*/

// THEME-related variables (inludes Bulma-variables to overwrite)
@import '~__theme/variables.scss';
// Third-party
@import "~bulma/bulma";
@import '~bulma-extensions/bulma-badge/src/sass/index.sass';
@import '~bulma-extensions/bulma-checkradio/src/sass/index.sass';
@import '~bulma-extensions/bulma-steps/src/sass/index.sass';
@import '~bulma-extensions/bulma-divider/src/sass/index.sass';
@import '~bulma-extensions/bulma-tooltip/src/sass/index.sass';
// App-styles
@import './base.scss';
// Theme-related overrides
@import '~__theme/theme.scss';
