.cmp-recommendation-lists {
  .cmp-sub-entries {
    .cmp-sub-entry {
      .card { height: 100%;
        .card-image {
          .cmp-sub-lnk-img {
            .cmp-sub-new-tag { position: absolute; bottom: 1em; left: 1em; }
          }
        }
      }
    }
  }
}
