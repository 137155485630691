.cmp-async-feedback { position: relative;
  @keyframes blink {
    from { opacity: 0.1; }
    to { opacity: 1; }
  }
  @-webkit-keyframes blink {
    from { opacity: 0.1; }
    to { opacity: 1; }
  }

  /*
  * In order to have the success- and failure icons the same size as the button font-size, the font-size has to be
  * set on the async-feedback component. Special classes which have their own font-size are then supposed to inherit
  * from the parent.
  */
  .button { font-size: inherit; }

  .cmp-sub-status-content { position: absolute; width: 100%; height: 100%; left: 0; top: 0; box-sizing: border-box;
      /*opacity: 0; transition: opacity 0.3s;*/
    .cmp-sub-status-icon { display: none; position: relative; width: 100%; height: 100%;;
      background: center center no-repeat;
    }
  }
  .cmp-loader {
    .cmp-sub-loading-symbol { top: 0; margin-top: 0; }
  }
  &.sub-status-uninitialized,
  &.sub-status-ready {
    .cmp-sub-status-content { display: none; }
  }
  &.sub-status-success,
  &.sub-status-error {
    .cmp-loader { visibility: hidden; }
    .cmp-sub-status-content {
      .cmp-sub-status-icon { animation: blink 1s cubic-bezier(0.31, 0, 0.67, 1) infinite; }
    }
  }
  &.sub-status-success {
    .cmp-sub-status-content {
      .cmp-sub-status-success { display: flex; }
    }
  }
  &.sub-status-error {
    .cmp-sub-status-content {
      .cmp-sub-status-error { display: flex; }
    }
  }
}
