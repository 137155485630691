.cmp-order-breadcrumbs {
  $transition: background-color 0.2s ease-out;
  $lighten-value: 20%;
  margin-bottom: 0 !important;
  .cmp-sub-entries {
    .cmp-sub-entry {
      &.cmp-sub-completed { cursor: pointer;
        .cmp-sub-marker { transition: $transition; }
        &:hover {
          .cmp-sub-marker{ background-color: lighten($steps-active-color, $lighten-value); transition: $transition;
          }
        }
      }
    }
  }
}
