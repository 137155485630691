/* Image Gallery */
.cmp-image-gallery { display: block; vertical-align: bottom; position: relative;
  .cmp-sub-img-wrapper { display: block; position: relative; box-sizing: content-box;
      border: 1px solid #ddd;
    .cmp-sub-img { }
    .cmp-tag-list { position: absolute; bottom: 1em; right: 1em; }
    .cmp-sub-new-tag { position: absolute; padding: 0.2em 1.2em; bottom: 1em; left: 1em; }
    &.clickable { cursor: pointer; }
  }
  .cmp-sub-thumbs { list-style: none; width: 100%; position: relative; margin-top: 5px;
    .cmp-sub-thumbs-nav { display: inline-block; box-sizing: border-box; width: 12.5%;  border: none;
        outline:none; visibility: hidden; vertical-align: middle; text-align: center; height: 100%;
        background: transparent; cursor: pointer;
      .fa { color: $primary; font-size: 5em;}
      &.more-thumbs { visibility: visible; }
    }
    .cmp-sub-thumb-entry { display: inline-block; box-sizing: border-box;
        vertical-align: middle; width: 23.66666666666667%; margin-right: 2%;
      .cmp-sub-figure { box-sizing: content-box; border: 1px solid #ddd;
        .cmp-sub-img {  }
        &.selected { border: 1px solid $primary; }
      }
      &:nth-child(4) { margin-right: 0; }
      &.fit-thumbs { width: 23.5%; margin-right: 2%;
        &:last-child { margin-right: 0; }
      }
    }
  }
  .ng-image-gallery {
    .ng-image-gallery-modal { z-index: $z-index-gallery; }
    /* Force hiding of loading image */
    .ng-image-gallery-loader {
      .spinner { display: none; }
    }
  }
}
