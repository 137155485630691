@import './bulk-prices/bulk-prices.scss';

.cmp-order-info {
  .cont-orderinfo {
    .cmp-sub-single-prices {
        .cmp-sub-price { display: none;
          &.has-price { display: block; }
        }
      .cmp-sub-cont-net-price:not(.has-price) + .cmp-sub-cont-gross-price.has-price,
      .cmp-sub-cont-gross-price.has-price:first-of-type { @extend .title; @extend .is-3; }
    }
  }
}
