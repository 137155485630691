@import './async-feedback/async-feedback.scss';
@import './badge-count/badge-count.scss';
@import './button-cart/button-cart.scss';
@import './button-more-actions/button-more-actions.scss';
@import './button-offer/button-offer.scss';
@import './button-user/button-user.scss';
@import './companion-suite/companion-suite.scss';
@import './companion-suite-display/companion-suite-display.scss';
@import './contact-infos/contact-infos.scss';
@import './dynamic-field/dynamic-field.scss';
@import './date-picker/date-picker.scss';
@import './expand-collapse-button/expand-collapse-button.scss';
@import './facets/facets.scss';
@import './featured-list/featured-list.scss';
@import './footer/footer.scss';
@import './formatted-address/formatted-address.scss';
@import './image-gallery/image-gallery.scss';
@import './loader/loader.scss';
@import './master-loader/master-loader.scss';
@import './message/message.scss';
@import './messages/messages.scss';
@import './my-account/my-account.scss';
@import './navigation-bar/navigation-bar.scss';
@import './order-breadcrumbs/order-breadcrumbs.scss';
@import './order-history-mini/order-history-mini.scss';
@import './order-info/order-info.scss';
@import './paginator/paginator.scss';
@import './permission/can-navigate-to.scss';
@import './price-modifier/price-modifier.scss';
@import './quick-order/quick-order.scss';
@import './recommendation-lists/recommendation-lists.scss';
@import './rename-field/rename-field.scss';
@import './search-field/search-field.scss';
@import './search-suggestions/search-suggestions.scss';
@import './slider/slider.scss';
@import './step-input/step-input.scss';
@import './tag-list/tag-list.scss';

.cmp-tag-nfc { font-size: 0.8em; font-weight: bold; border: 1px solid #dbdbdb; border-radius: 3px; padding: 0 0.2em;
  vertical-align: text-bottom
}
