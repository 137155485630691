/* Slider */
.cmp-slider { width: 100%; position: relative; overflow: hidden;
  // default styles
  .cmp-sub-carousel-cont { box-sizing: border-box; max-height: 420px;
  .cmp-sub-carousel-items { text-align: center; display: block; margin-left: auto; margin-right: auto;
        width: 100%; max-height: 100%; height: auto; box-sizing: border-box;
      .cmp-sub-item { display: block; max-height: 100%; max-width: 100%; width: auto; height: auto;
        .cmp-sub-carousel-img { display: block; margin-left: auto; margin-right: auto;
          max-width: 100%; width: auto; height: auto;
        }
      }
    }
    &[rn-carousel] {
      > .cmp-sub-carousel-items { position: relative; margin-left: -100%;
        &:first-child { margin-left: 0; }
      }
    }
  }
  .cmp-sub-single-entry-cont {
    .cmp-sub-single-img-lnk { display: block;
      .cmp-sub-single-img { width: 100%; }
    }
  }
  .rn-carousel-controls {
    .rn-carousel-control { width: 0; height: 0; color: transparent;  transition: all 0.3s ease-out; top: 45%; }
    .rn-carousel-control-prev { margin-left: -50px;
      @include mx-arrow($_color: #fff, $_direction: 'left', $_align: 'none', $_side_width: 20px);
    }
    .rn-carousel-control-next { margin-right: -50px;
      @include mx-arrow($_color: #fff, $_direction: 'right', $_align: 'none', $_side_width: 20px);
    }
  }
  &:hover .rn-carousel-controls {
    .rn-carousel-control { margin: 0; }
  }
}
