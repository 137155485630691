.cmp-quick-order {
  .cmp-sub-tabs {
    .cmp-sub-tabs-head-cont,
    .cmp-sub-tab-data {
      margin-bottom: 0.5rem;
    }
    .sub-inline-loader,
    .cmp-sub-lbl-order-data {
      display: block;
    }
  }
}
