.cmp-project-list-position {
  .sub-project-list-title {
    .sub-shared-infos { font-size: $size-7; }
    .sub-title-name { min-height: 1.6rem;
      .sub-name { vertical-align: middle; }
      .sub-btn-more-actions-mobile { font-weight: normal; }
      .tag { line-height: 1.5rem; font-weight: normal;
        &.sub-tag-shared { display: none; }
      }
    }
  }
  &.own-shared-list {
    .sub-project-list-title {
      .sub-title-name {
        .tag {
          &.sub-tag-shared { display: inline-block; }
        }
      }
    }
  }
  &.hidden-list {
    .title .sub-name,
    .sub-details,
    .sub-shared-infos { opacity: 0.5; }
  }
}
