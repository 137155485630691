.view-basket-cart {
  .has-cursor-info { cursor: help; }
  .sub-position-row {
      .sub-is-external { cursor: not-allowed; }
    .cell-amount {
      .cmp-step-input { font-size: $size-7; }
    }
    .cell-unit-price {
      .sub-quote-detail { line-height: 1em; }
    }
  }
}
