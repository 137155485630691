.view-search-result {
  .main-content {
    .sub-cont {
      .sub-main-cont {
        .sub-main-row {
          .sub-main-panel {
            .cont-result-list {
              .result-entry {
                .sub-loader-prices-and-availability {
                  display: inline-block;

                  .btn-check-prices-availability {
                    &.disabled {
                      opacity: 0.5;
                      cursor: not-allowed;
                    }
                  }
                }
                .tbl-articles {
                  th { @extend .is-nowrap;}
                  .cmp-tag { margin-bottom: 0; }
                }
              }
            }
          }
        }
      }
    }
  }
}
