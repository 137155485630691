.cmp-featured-list {
  .cmp-sub-list {
    .cmp-sub-entry { text-align: center;
      .card { height: 100%; }
      .cmp-sub-img-wrapper {
        .cmp-sub-lnk-img {
          .cmp-tag-list { position: absolute; left: 0; right: 0; bottom: -0.7em; margin-left: auto; margin-right: auto;
            &.tags { justify-content: center; }
          }
        }
      }
      .cmp-sub-lnk-title { white-space: pre-wrap; word-wrap: break-word; }
    }
  }
}
