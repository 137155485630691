.cmp-step-input {
    .control,
    .button,
    .input { font-size: inherit; text-align: center; }
    .input { font-family: $family-generic; }
  .cmp-sub-cont-input { width: 4em; }
  &.invalid-by-msg {
    .input,
    .button { @extend .invalid-by-msg; }
  }
}
