.cmp-companion-suite { margin-top: 1rem; margin-bottom: 1.5rem; padding: 0.75rem;
  .cmp-sub-header { flex: 1; margin-bottom: 0.25rem; }
  .cmp-sub-properties { margin-left: -0.5rem; margin-right: -0.5rem;
    .cmp-messages { margin: 0 0.5rem;
      .cmp-message { padding: 0.3em 0.7em !important; margin-bottom: 0.2rem !important; font-size: 0.9em;
        .delete { top: 0.4rem; }
      }
    }
    .cmp-sub-param-group { display: flex; flex-flow: row wrap; align-items: flex-end; justify-content: stretch;
      .cmp-dynamic-field { position: relative; display: flex; flex-flow: column nowrap; flex-shrink: 0.5;
          flex-grow: 1; /* Setting width, to allow text to wrap */ width: 50%; padding: 0.3rem 0.5rem;
          font-size: 0.9em;
        label { line-height: 1.2em; }
      }
    }
  }
}
