.view-quote-detail {
  .sub-quote-list {
    .sub-position-row {
        .sub-is-external { cursor: not-allowed; }
      .sub-cell-detail-content {
        .sub-lnk-add-to-basket { display: inline-block; }
      }
    }
  }
}
