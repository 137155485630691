/* LOADER COMPONENT */
.cmp-loader { position: relative; opacity: 1; transition: opacity 0.3s ease-out;
  & > .cmp-sub-loading { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: none;
      text-align: center;
    .cmp-sub-loading-symbol { position: relative; display: inline-block; width: 100%; height: 100%; max-width: 64px;
      max-height: 21px; top: 50%; margin-top: -10px;
      /*noinspection CssUnknownTarget*/background:$spinner no-repeat center center;
      background-size: contain;
    }
  }
  &.loading {
    .cmp-sub-content { visibility: hidden; }
    .cmp-sub-loading { display: block; }
  }
  &.sub-inline-loader { display: inline-block;
    &.loading {
      .cmp-sub-loading { display: inline-block; }
    }
  }

  // Master-loader enabled
  &.use-master-loader {
    .cmp-sub-loading {
      .cmp-sub-loading-symbol { display: none; }
    }
    &.loading { opacity: 0.15;
      .cmp-sub-content { visibility: visible; }
    }
  }
}

