// IMAGES

$main-logo: url("~__theme_assets/zg_logo_wh.svg");
$main-logo-small: url("~__theme_assets/zg_icon_wh.svg");


// TYPOGRAPHY

$family-primary: nimbus-sans, BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;


// COLORS

$orange-light:  #f18700;
// Overridden colors
$orange:        #d74703;
$red:           #d74703;
$grey-light:    #9c9c9c;


// DERIVED COLORS

$link:    $orange;
$primary: $orange-light;

$danger:  $red;
$info:    $grey-light;

// Bulma extensions
$steps-active-color:    $primary;
$steps-completed-color: $primary;


// OVERRIDDEN LAYOUT VALUES

$section-padding: 1.5rem;
$radius-large: 0.125rem;


// APPLICATION COLORS

$light-text-color:  $grey-light;
