.view-order-detail {
  .order-positions {
      /* A height on the table has to be set, in order to make the `height: 100%` work on the cell */
      height: 1rem;
    th,
    td {
      &.col-status-delivery-date { white-space: nowrap; }
      &.col-article { width: 50%; }
    }

    tbody {
      tr {
        &.show-companion-suite-config {
          td { border-bottom-width: 0; }
        }
        &:last-child td,
        &:last-child th { border-bottom-width: 1px; }
        &:last-of-type {
          td,
          th { border-bottom-width: 1px; }
        }
        &.row-entry {
          td { /* `height: 100%` has to be set that containing elements can assume the height of the cell */
              height: 100%;
            .sub-not-available { cursor: default; }
            &.col-action {
              .cmp-sub-buttons { display: flex; flex-flow: column wrap; justify-content: space-between;
                align-items: flex-end; height: 100%;
                .cmp-sub-toggle-buttons { display: flex; flex-flow: row wrap;
                  .cmp-sub-text { font-weight: bold; }
                }
              }
            }
          }
        }
      }
    }
  }
}
