@import './project-list-position/project-list-position.scss';
@import './project-list-position/project-list-position-undo.scss';

.view-project-list {
  &.sub-shared-list {
    .view-title {
      .sub-tag-shared { vertical-align: middle }
    }
  }
}
