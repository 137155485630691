.cmp-nav-bar {
  .cmp-sub-nav-bar-left { flex-grow: 1;
    .cmp-app-logo { display: flex; margin-left: 1rem; width: 2rem; height: 2rem; align-self: center;
      background: $main-logo-small no-repeat; background-size: contain;
    }
    .cmp-sub-search-wrapper { flex: 1; }
    @include from($navbar-breakpoint) {
      .cmp-app-logo { margin-right: 0.5rem; width: 12.5rem; height: 1rem; background-image: $main-logo; }
    }
  }
  .cmp-sub-cont-buttons { flex-grow: 0; }
  .cmp-master-loader { position: absolute; bottom: 0; }

  &.is-danger {
    .cmp-master-loader {
      .sub-bar { background: linear-gradient(
          to right,
          transparentize($white, 0.88),
          transparentize($white, 0.88) 0%,
          transparentize($white, 0.12) 88%,
          $white 100%
        );
      }
    }
  }
}
