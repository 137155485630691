.cmp-companion-suite-display { display: flex; flex-flow: column nowrap; padding: 0.75rem; align-items: stretch;
  .cmp-sub-cont-head { margin-bottom: 0.5rem;
    .cmp-sub-header { margin-bottom: 0.5rem; }
  }
  .cmp-sub-cont-properties { display: flex; flex-flow: column nowrap; align-items: stretch;
    .cmp-sub-header { margin-bottom: 0.25rem; }
    .cmp-sub-properties { display: flex; flex-flow: column nowrap; align-items: stretch;
      .cmp-sub-param-group { display: flex; flex-flow: row wrap; margin-left: -0.5rem; margin-right: -4rem;
        .cmp-sub-property { display: flex; flex-flow: row wrap; justify-content: space-between; width: 50%;
          padding: 0 4rem 0 0.5rem; font-size: 0.9em;
        }
      }
    }
  }
}
