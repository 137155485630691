.view-checkout-review {
  .has-cursor-info { cursor: help; }
  .order-simulation-positions {
    .row-entry {
        .sub-is-external { cursor: not-allowed; }
        .cmp-price-modifier { font-size: 0.75rem; font-style: italic; }
      .col-net-price {
        .sub-quote-detail { line-height: 1em; }
      }
    }
  }
}
