/* Facets */
.cmp-facets {
  .sub-facet-groups {
    .cmp-sub-group { display: none; margin-bottom: 2em;
      .cmp-sub-entries {
        .cmp-sub-header { @extend .has-margin-bottom-xs; }
        .cmp-sub-entry {
          .cmp-sub-label { display: block; font-size: $is-size-6-5; }
        }
      }
      &.cmp-sub-filters-visible { display: block; }
    }
  }
  @include from($tablet) {
    .sub-facet-groups {
      .cmp-sub-group { display: block; }
    }
  }

  /* Override styles for featured facets */
  &.cmp-facets-list-featured {
    @include from($tablet) {
      .sub-facet-groups {
        .cmp-sub-group { margin-bottom: 0;
          &:nth-of-type(n + 5) { display: none; }
        }
      }
    }
  }
}
