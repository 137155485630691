.cmp-search-suggestions { position: absolute; display: block; bottom: 0;
    box-sizing: border-box; white-space: nowrap;
  .cmp-sub-popover-anchor { position: relative; display: block; top: -1px; z-index: $z-index-autosuggest;
    .cmp-sub-cont-popover { position: absolute; display: inline-block; min-width: 100%; box-sizing: border-box;
        background-color: white;
      #SearchSuggestionScrollable { box-sizing: content-box; overflow-y: scroll; top: -1px; overflow-x: hidden;
          max-height: 329px;
        .cmp-sub-list { margin: 0;
          .cmp-sub-entry {
            .cmp-sub-text { text-decoration: none; cursor: pointer;
              em { font-style: normal; font-weight: bold; }
            }
          }
        }
      }
    }
  }
}
