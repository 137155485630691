/*
* Inherit the Bulma-styles for the footer (the footer is an external template, thus we can't set any CSS-classes
* on it)
*/
.app-root {
  .cmp-footer { @extend .footer; position: absolute; width: 100vw; bottom: 0; background-color: transparent;
      padding-bottom: 2rem;
    .cmp-footer-cont { @extend .content; display: block; text-align: center; padding: 2rem;
      .cmp-footer-cont-list { display: inline-block; margin: 0.5rem; }
    }
  }
}
