#EShopApp {
  .cmp-nav-bar {
    .cmp-sub-nav-bar-left {
      .cmp-app-logo { margin-top: 0.25rem; width: 3rem;
        @include from($desktop) { margin-top: 0.1rem; width: 12rem; height: 1.25rem; }
      }
    }
  }
}
.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .input, .textarea, .button {
  border-radius: 0.125rem;
}
