.cmp-btn-basket-cart {
  .cmp-sub-cont-title {
    .cmp-sub-title.badge { font-family: $family-generic;
      .cmp-sub-icon { font-size: 2rem; }
    }
  }
  .navbar-dropdown { position: absolute;
      .navbar-item { display: block; }
    .cmp-sub-entry {
      .cmp-sub-entry-detail { display: flex; flex-flow: row nowrap; justify-content: space-between;
        .cmp-sub-amount { margin-left: 1.5rem; }
      }
      &.sub-is-external { cursor: not-allowed; }
    }
    .cmp-sub-cont-total-price { display: flex; flex-flow: row nowrap; justify-content: space-between;
      .cmp-sub-total-price { margin-left: 1.5rem; }
    }
  }
}
