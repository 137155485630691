.view-offer-list {
  .cmp-sub-content .sub-cont-table .sub-list {
    .sub-position-row {
        .sub-net-price,
        .cell-total.price { font-weight: bold; }
      .cell-amount {
        .cmp-step-input { font-size: $size-7; }
      }
      .cell-unit-price {
        .sub-strikethrough-price { text-decoration: line-through; }
      }
    }
  }
}
